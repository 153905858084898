import { FaMobile, FaGit, FaLaptopCode, FaCss3, FaPhp, FaHtml5, FaJs, FaLinux, FaNodeJs, FaReact, FaSass, FaVuejs, FaMicrophone, FaMediumM, FaNpm } from "react-icons/fa";
    import { GiSwordsEmblem } from "react-icons/gi";
import '../components/js/custom'
   

function Main() {

    return (
      <div id="Interest" className="section">
        <h2>İlgi Alanlarım </h2>  
        <div className="interests">
            <div className="in">
                <FaJs />
                <div className="title">Javascript</div>
            </div>
            <div className="in">
                <FaReact />
                <div className="title">React</div>
            </div>
            <div className="in">
                <FaVuejs />
                <div className="title">VUE</div>
            </div>
            <div className="in">
                <FaHtml5 />
                <div className="title">HTML</div>
            </div>
            <div className="in">
                <FaCss3 />
                <div className="title">CSS</div>
            </div>

            <div className="in">
                <FaNodeJs />
                <div className="title">NodeJS</div>
            </div>
            <div className="in">
                <FaPhp />
                <div className="title">PHP</div>
            </div>
            <div className="in">
                <FaSass />
                <div className="title">Sass</div>
            </div>
            <div className="in">
                <FaGit />
                <div className="title">Git (Github / GitLab)</div>
            </div>
            <div className="in">
                <FaLinux />
                <div className="title">Linux (Ubuntu) </div>
            </div>

            <div className="in">
                <FaMediumM />
                <div className="title">Medium</div>
            </div>
            <div className="in">
                <FaNpm />
                <div className="title">NPM</div>
            </div>
            <div className="in">
                <FaMobile />
                <div className="title">Mobil Uygulama Geliştirme</div>
            </div>
            <div className="in">
                <FaLaptopCode />
                <div className="title">Web Uygulama Geliştirme</div>
            </div>
            <div className="in">
                <FaMicrophone />
                <div className="title">Podcast</div>
            </div>
            <div className="in">
                <GiSwordsEmblem />
                <div className="title">Anime</div>
            </div>
            
            
        </div>
       
      </div>
    );
  }
  
  export default Main;
  