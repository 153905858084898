import { FaHome, FaUser, FaCode, FaGithub, FaMediumM, FaTwitter, FaInstagram, FaLinkedinIn } from "react-icons/fa";

function SideBar() {
    return (
      <div className="Sidebar">
        <h4> Alertis </h4>
        <div className="round-img"> <img src={process.env.PUBLIC_URL+"/assets/img/me.jpg"} width="300" alt="Osman Yavuz Demir" /></div>
        <h2> Osman Yavuz Demir</h2>

        <ul className="menu">
            <li ><a href="#Main" className="links"> <FaHome /> Anasayfa </a>  </li>
            <li > <a href="#About" className="links"> <FaUser /> Hakkımda </a> </li>
            <li> <a href="#Interest" className="links"> <FaCode /> İlgi Alanlarım </a> </li>
            <li>  <a href="#Project" className="links"> <FaGithub /> Projelerim </a> </li>
            <li> <a href="#Post" className="links"> <FaMediumM/> Yazılarım </a> </li>
        </ul>

        <ul className="social">
            <li> <a href="https://twitter.com/Alertisx" target="_blank"  rel="noreferrer"> <FaTwitter /> </a> </li>
            <li> <a href="https://www.instagram.com/alertisx/" target="_blank"  rel="noreferrer"> <FaInstagram /> </a> </li>
            <li> <a href="https://www.linkedin.com/in/osmanyavuzdemir/" target="_blank"  rel="noreferrer"> <FaLinkedinIn /> </a> </li>
            <li> <a href="https://github.com/Alertis" target="_blank"  rel="noreferrer"> <FaGithub /> </a>  </li>
            <li> <a href="https://medium.com/@osmanyavuzdemir96" target="_blank"  rel="noreferrer">  <FaMediumM/>  </a></li>
        </ul>
      </div>
    );
  }
  
  export default SideBar;
  