import {useState, useEffect} from 'react'
import Modal from 'react-modal';

function Main() {
    const [posts, setPosts] = useState([])
    const [modalIsOpen, setIsOpen] = useState(false)
    const [title, setTitle] = useState("")
    const [desc, setDesc] = useState("")
    const [link, setLink] = useState("")


    const customStyles = {
      content: {
        top: '30%',
        left: '30%', 
        right: 'auto',
        bottom: 'auto', 
        transform: 'translate(-30%, -30%)',
        backgroundColor: '#353353',
        borderRadius: '15px',
        marginRight: '10%',
        overflow: 'scroll',
        zIndex: 9999,
        height: '80vh',
        width: '95%',
        padding: '1rem'
      },
    };
    useEffect (() =>{
      fetch('https://api.rss2json.com/v1/api.json?rss_url='+process.env.REACT_APP_MEDIUM_RSS)
      .then(res => res.json()).then(data => setPosts(data.items))
    },[])

    return (
      <div id="Post" className="section">
        <h2>Yazılarım </h2> 
       
        <div className="posts">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setIsOpen(false)}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <h1>{title}</h1>
          <h4><a  style={{color: '#FF4C60'}} href={link} target='_blank' rel='noreferrer'>Yazıyı medium üzerinden okumak için tıklayınız</a></h4>
          <div dangerouslySetInnerHTML={{__html:desc}}></div>
        </Modal>
          {posts.length > 0 && posts.map(p =>
            <div onClick={() => {
              setIsOpen(true);
              setTitle(p.title);
              setDesc(p.description);
              setLink(p.link);
            }}>
              <div className="post">
                  <div className="img">
                      <img src={p.thumbnail}  alt={p.title}/>
                  </div>
                  <div className="title"> {p.title} </div>
                  <div className="tags">
                    {p.categories.map(category => 
                      <div className="tag">
                        {category}
                    </div>
                    )}
                  </div>
              </div>
            </div>
          )}
          
         
        </div>
      
      </div>
    );
  }
  
  export default Main;
  
