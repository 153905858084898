import { FaNode, FaNpm, FaCode, FaGit, FaBootstrap, FaCss3, FaPhp, FaHtml5, FaJs, FaLinux, FaNodeJs, FaReact, FaSass, FaVuejs, FaGithub, FaMediumM, 
    FaTwitter, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import '../components/js/custom'
   

function Main() {

    return (
      <div id="Main"  className="section">
        <div className="container">
            <div className="intro">
                <h1>Osman Yavuz Demir</h1>
                <h1>
                    <span
                        className="txt-rotate"
                        data-period="2000"
                        data-rotate='[ "Frontend Geliştiricisi.", "Javascript Geliştiricisi.", "Yazılım Mühendisi."]'></span>
                </h1>
                <ul className="social">
                    <li> <a href="https://twitter.com/Alertisx" target="_blank"  rel="noreferrer"> <FaTwitter /> </a> </li>
                    <li> <a href="https://www.instagram.com/alertisx/" target="_blank"  rel="noreferrer"> <FaInstagram /> </a> </li>
                    <li> <a href="https://www.linkedin.com/in/osmanyavuzdemir/" target="_blank"  rel="noreferrer"> <FaLinkedinIn /> </a> </li>
                    <li> <a href="https://github.com/Alertis" target="_blank"  rel="noreferrer"> <FaGithub /> </a>  </li>
                    <li> <a href="https://medium.com/@osmanyavuzdemir96" target="_blank"  rel="noreferrer">  <FaMediumM/>  </a></li>
                </ul>
                <div className="sendMail">
                    <div className="button"> <a href="mailto:osmanyavuzdemir96@gmail.com"> E-posta Gönder </a> </div>
                </div>
            </div>    
        </div>  
        <div className="parallax"> 
            <FaCode className="i1" />
            <FaGit className="i2"/>
            <FaBootstrap className="i3"/>
            <FaCss3 className="i4"/>
            <FaHtml5 className="i5"/>
            <FaJs className="i6"/>
            <FaLinux className="i7"/>
            <FaNodeJs className="i8"/>
            <FaNode className="i9"/>
            <FaNpm className="i10"/>
            <FaPhp className="i11"/>
            <FaReact className="i12"/>
            <FaSass className="i13"/>
            <FaVuejs className="i14"/>
        </div>
      </div>
    );
  }
  
  export default Main;
  