import React, {useEffect} from 'react'
import SideBar from './components/leftSidebar'
import Main from './pages/main'
import About from './pages/about'
import Interest from './pages/interest'
import Project from './pages/projects'
import Posts from './pages/posts'
import {Helmet} from "react-helmet";  
import ReactGA from "react-ga"
ReactGA.initialize('G-6QW0KW6LP2')


function App() {
  useEffect( () => {

    // This line will trigger on a route change
    ReactGA.pageview(window.location.pathname + window.location.search); 

  });
  return (
    <div className="App">
       <Helmet>
        <title>Osman Yavuz Demir</title>
        <meta charSet="utf-8" />
        <meta name="description" content="Meslek Lisesinden Bilgisayar Programcılığına oradan da Yazılım Mühendisliğine geçtim. ReactJS, VueJS ve React Native geliştiriciliği yaptım." />
         <meta name="keywords" content="Yazılım Mühendisi, Frontend Developer, Arayüz Geliştiricisi, Hackathon, Javascript, VueJS, ReactJS, React Native, NodeJS, Ubuntu"></meta>
      </Helmet>
      <SideBar />
      <div className="page"> 
        <Main />
        <About />
        <Interest />
        <Project />
        <Posts />
      </div>
    </div>
  );
}

export default App;
