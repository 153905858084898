import { FaGraduationCap, FaBusinessTime, FaMicrophone } from "react-icons/fa";
import '../components/js/custom'

function Main() {

    return (
      <div id="About" className="section">
        <h2>Hakkımda </h2>  
        <div className="info">
            <p> Merhaba, ben Osman Yavuz Demir. Kimi zaman WEB kimi zaman ise mobil projeler geliştiren ama geliştirdiği her projede Javascripten yararlanan
                bir frontend geliştiricisiyim. Üsküdar Anadolu Ticaret Meslek Lisesi Bilişim Teknolojileri alanından mezunum. Yazılım ile ilgilenmeye lise
                yıllarında başladım. İlk iş deneyimimi de yine lise yıllarımda yaptığım  uzun dönem staj ile yaşadım. Daha sonra Kocaeli Bilgisayar 
                Programcılığı bölümüne geçiş yaptım. Aynı dönemde bir yazılım şirketinin yazılım destek departmanında işe başladım ve uzun bir süre bu 
                departmanda çalışmaya devam ettim. DGS ile Manisa Celal Bayar Üniversitesi Yazılım Mühendisliği bölümüne geçiş yaptım. Bir start up
                firmasında ReactJS kullanarak frontend alanında staj yaptım. Okul hayatım boyunca Bilişim Topluluğu aracılığı ile çeşitli etkinliklere 
                ve Hackathonlara katıldım. İzmir Climathon 2019 Etkinliğinde geliştirdiğimiz uygulama ile 3. lük ödülü kazandık. Üniversitenin son senesinde
                bir şirkette Frontend Geliştirici olarak çalışmaya başladım. 2021 yılında ise Celal Bayar Üniversitesi Yazılım Mühendisliği bölümünden mezun oldum.</p>

            <p> Çalıştığım yerde VueJS ile geliştirme yapıyorum. Kendi projelerimde ise Vue veya ReactJS kullanıyorum. Zaman zaman mobil uygulama geliştiriciliği ile
                ilgileniyorum ve React Native kullanıyorum. Ciddi olarak Backend geliştiriciliği ile ilgilenmiyorum fakat ihtiyacım olursa Kendimce basit
                API'ler oluşturmak için NodeJS kullanıyorum. Genel olarak Javascript geliştiriciliğine ilgi duyuyorum ve şimdilik bu durumdan gayet memnunum.
                Gelecekte de Javascript üzerinden ilerlemeyi planlıyorum. Bir Medium hesabım var ve bazen ilgimi çeken konular ile ilgili yazılar yazmaya
                çalışıyorum.</p>

            <p> Boş zamanlarımda okuldan 6 arkadaş ile oluşturduğumuz bir grup ile Sıfırdan Bire adında podcast serileri çekiyor ve yayınlıyoruz. Hikaye tabanlı
                ve boardgame tarzındaki oyunları oynamaktan hoşlanıyorum. Anime izlemeyi Dizi/Film izlemeye tercih ediyorum.</p>    
        </div>
        <div className="timeLineComp">
            <div className="timeLines">
                <div className="timeLine">
                    <FaGraduationCap />
                    <span> 2010 - 2014</span>
                    <h3> Üsküdar Anadolu Ticaret Meslek Lisesi </h3>
                    <p> Bilişim Teknolojileri bölümü Web Tasarım Alanı</p>
                </div>
                <div className="timeLine">
                    <FaBusinessTime />

                    <span> 2013 - 2014</span>
                    <h3> MCKare Yazılım - Staj </h3>
                    <p> Lise stajımda ASP.NET, MSSQL, HTML, CSS ve JQUERY kullanarak dinamik kurumsal web siteleri geliştirdim. </p>
                </div>
                <div className="timeLine">
                    <FaGraduationCap />
                    <span> 2014 - 2016</span>
                    <h3> Kocaeli Üniversitesi</h3>
                    <p> Bilgisayar Programcılığı Bölümü </p>
                </div>
                <div className="timeLine">
                    <FaBusinessTime />
                    <span> 2015 - 2018 </span>
                    <h3> Sanat Teknoloji - Yazılım Destek</h3>
                    <p> Yoğun olarak MySQL ile çalıştım. Şirketin geliştirmiş olduğu Alarm Merkezi Yönetimi yazılımının kurulumu ve ortaya
                        çıkan sorunların giderilmesinde yer aldım. Delphi, PHP, Basic ile projeler geliştirdim. Android ve IOS ortamlarında 
                        uygulamalar yayınladım. </p>
                </div>
                <div className="timeLine">
                    <FaGraduationCap />

                    <span> 2018 - 2021</span>
                    <h3> Manisa Celal Bayar Üniversitesi</h3>
                    <p> Yazılım Mühendisliği Bölümü</p>
                </div>
            </div>
            <div className="timeLines">
                <div className="timeLine">
                    <FaBusinessTime />
                    <span> 2019 - 2019</span>
                    <h3> KodiaSoft - Staj </h3>
                    <p> Gönüllü Staj programı ile HTML CSS dosyaları hazır olan şirket projesinin React.js componentlerini oluşturdum. 
                        Proje içerisinde Redux ve CSS için sass yapılarını kullandım.</p>
                </div>
                <div className="timeLine">
                    <FaBusinessTime />
                    <span> 2020 - 2023</span>
                    <h3> Sanat Teknoloji - Frontend Developer </h3>
                    <p> Şirket bünyesinde sıfırdan geliştirilen web projesinin core takımındayım ve frontend geliştirmelerini VueJS ile yapmaktayım. 
                        Ek olarak şirket bünyesinde React Native ile uygulamalar geliştirmekteyim
                    </p>
                </div>
                <div className="timeLine">
                    <FaMicrophone />
                    <span> 2021 - Devam Ediyor</span>
                    <h3> Sıfırdan Bire - Kurucu Ekip Üyesi </h3>
                    <p> Bir grup yeni mezun yazılım mühendisi olarak oluşturduğumuz podcast topluluğunda her hafta kendi belirlediğimiz konular üzerinde 
                        sohbet etmekteyiz
                    </p>
                </div>
                 <div className="timeLine">
                    <FaBusinessTime />
                    <span> 2023 - Devam Ediyor</span>
                    <h3> Kaft - Frontend Developer </h3>
                    <p> Şirket bünyesinde geliştirilen backoffice projesinin frontend takımındayım ve geliştirmeleri VueJS ile yapmaktayım. 
                    </p>
                </div>
            </div>
        </div>
       
      </div>
    );
  }
  
  export default Main;
  
