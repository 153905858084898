import { FaMobile, FaAndroid, FaReact, FaTv, FaCode } from "react-icons/fa";

function Main() {

    return (
      <div id="Project" className="section">
        <h2>Projelerim </h2>
        <div className="projects">
          <a href="https://play.google.com/store/apps/details?id=com.socceranalyze" target="_blank" rel="noreferrer">
            <div className="project">
              <div className="img">
                <img src="https://play-lh.googleusercontent.com/vtU2wWPAeOZ2NjyFRI03oFpoa8sM7noH3EB7AbmtsFTfUu0rczXih1avadO6N7dHoyZ7=s180" alt="Mobile Tipster "/>
              </div>
              <div className="info">
                <div className="title"> Mobile Tipster </div>
                <div className="desc">
                  <p> Maç tahminleri yapan bir API'den sonuçları alıp kullanıcıya liste halinde sunan React Native ile geliştirilmiş mobil uygulamadır. </p>
                </div>
              </div>
              <div className="tags">
                <div className="tag">
                  <FaMobile /> Mobil
                </div>
                <div className="tag">
                  <FaAndroid /> Android
                </div>
                <div className="tag">
                  <FaReact /> React Native
                </div>
              </div>
            </div>
          </a>

          <a href="https://github.com/Alertis/memorize" target="_blank" rel="noreferrer">
            <div className="project">
              <div className="img">
                <img src="https://play-lh.googleusercontent.com/m7tSMm8yzdUzcfZ-0AWaJKbnbkojJo3-XvMhswwkZq-gUO_IHpSNIBtUpG82vfm3sg=s180" alt="Memorize" />
              </div>
              <div className="info">
                <div className="title"> Memorize </div>
                <div className="desc">
                  <p> Memorize, kendi belirlediğiniz İngilizce kelimeleri belirli bir algoritmaya göre sürekli cevaplayarak ezberleyebildiğiniz React Native
                    ile geliştirilmiş mobil uygulamadır. </p>
                </div>
                
              </div>

              <div className="tags">
                <div className="tag">
                  <FaMobile /> Mobil
                </div>
                <div className="tag">
                  <FaAndroid /> Android
                </div>
                <div className="tag">
                  <FaReact /> React Native
                </div>
              </div>
            </div>
          </a>

          <a href="https://happy-spence-dd9d9d.netlify.app/" target="_blank" rel="noreferrer">
            <div className="project">
              <div className="img">
                <img src={process.env.PUBLIC_URL+"/assets/img/stok-takip.png"} style={{height: 200}} alt="Yazılım Bakımı - Stok Takip "/>
              </div>
              <div className="info">
                <div className="title"> Yazılım Bakımı - Stok Takip </div>
                <div className="desc">
                  <p> Celal Bayar Üniversitesi 4. sınıf Yazılım Bakımı dersi stok takip projesi için React ile oluşturulan frontend frontend projesidir...  </p>
                </div>
              </div>
              <div className="tags">
                <div className="tag">
                  <FaTv /> WEB
                </div>
                <div className="tag">
                  <FaReact /> React
                </div>
              </div>
            </div>
          </a>

          <a href="https://github.com/Alertis/Travelify" target="_blank" rel="noreferrer">
            <div className="project">
              <div className="img">
                <img src="https://raw.githubusercontent.com/Alertis/Travelify/main/screenshots/screenshots.png" style={{ width: 300, height: 300}} alt="Travelify - Tez Projesi"/>
              </div>
              <div className="info">
                <div className="title"> Travelify - Tez Projesi </div>
                <div className="desc">
                  <p> Travelify uygulaması mobil platformlarda hizmet veren React Native ile geliştirilmiş bir rehber uygulamasıdır.  </p>
                </div>
              </div>
              <div className="tags">
                <div className="tag">
                  <FaMobile /> Mobil
                </div>
                <div className="tag">
                  <FaAndroid /> Android
                </div>
                <div className="tag">
                  <FaReact /> React Native
                </div>
              </div>
            </div>
          </a> 

          <a href="https://github.com/Alertis/sanal-sinav-sistemi" target="_blank" rel="noreferrer">
            <div className="project">
              <div className="img">
                <img src="https://raw.githubusercontent.com/Alertis/sanal-sinav-sistemi/master/screenshots/1.jpeg" style={{ width: 300, height: 300}} alt="Yazılım Sınama - Sanal Sınav Sistemi"/>
              </div>
              <div className="info">
                <div className="title"> Yazılım Sınama - Sanal Sınav Sistemi </div>
                <div className="desc">
                  <p> React ile geliştirilen sisteme kayıt olan öğretmenlerin, öğrencilere online sınav yapabilecekleri bir web uygulamasıdır.  </p>
                </div>
              </div>
              <div className="tags">
                <div className="tag">
                  <FaTv /> WEB
                </div>
                <div className="tag">
                  <FaReact /> React
                </div>
              </div>
            </div>
          </a> 
          
          <a href="https://github.com/toltarisa/Hackathon-App" target="_blank" rel="noreferrer">
            <div className="project">
              <div className="img">
                <img src="https://raw.githubusercontent.com/toltarisa/Hackathon-App/master/screenshots/Screenshot_4.jpg" style={{ height: 300}} alt="Climathon 2019 - DAKS " />
              </div>
              <div className="info">
                <div className="title"> Climathon 2019 - DAKS </div>
                <div className="desc">
                  <p> Climathon 2019 da 3.lük ödülü kazandığımız kullanıcının konumuna en yakın toplanma alanlarını gösteren React Native ile geliştirilen mobil uygulamadır  </p>
                </div>
              </div>
              <div className="tags">
                <div className="tag">
                  <FaMobile /> Mobil
                </div>
                <div className="tag">
                  <FaAndroid /> Android
                </div>
                <div className="tag">
                  <FaReact /> React Native
                </div>
                <div className="tag">
                  <FaCode /> Hackathon
                </div>
                
              </div>
            </div>
          </a> 
          

        </div>  

       
      </div>
    );
  }
  
  export default Main;
  